.hideForNoPrint {
  display: none !important;
}

@media print {
  .hideForPrint {
    display: none !important;
  }
  .displayOnPrint {
    display: inherit !important;
  }
  .noBreakAfterPrint {
    page-break-after: avoid;
  }
}
